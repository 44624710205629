<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <!-- <h2 class="brand-text text-primary ml-1">
            Ticket
          </h2> -->
        </b-link>

        <!-- <b-card-title class="mb-1">
         Тикет системд тавтай морилно уу 👋
        </b-card-title> -->
        <!-- <b-card-text class="mb-2">
          Та бүртгэлээрээ нэвтэрч асуудлаа шийдүүл
        </b-card-text> -->

        <!-- form -->
        <validation-observer
          ref="loginValidation"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <!-- <b-form-group
              label-for="email"
              label="И-Мэйл"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="И-Мэйл"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- username -->
            <b-form-group
              label-for="username"
              label="Хэрэглэгчийн нэр"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  name="login-username"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Хэрэглэгчийн нэр"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Нууц үг</label>
                <b-link :to="{name:'forgot-password'}">
                  <small>Нууц үгээ мартсан?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Нууц үг"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Намайг сана
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
                :disabled="invalid"
              >
                Нэвтрэх
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Хэрэв та бүртгэлгүй бол? </span>
          <b-link :to="{name:'register'}">
            <span>Бүртгүүлэх</span>
          </b-link>
        </b-card-text>

        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
// Add the required rule
extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});

// Add the email rule
extend('email', {
  ...email,
  message: 'Заавал и-мэйл бүтэцтэй байна'
});
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.username,
            password: this.password,
          }).then(response => {
            console.log("RESSSS", response)
            console.log(response.data.success)

            if (response.data.success) {
              let userData = { ...response.data.response.userData, ability: [{action: "manage", subject: "all"}]}
              useJwt.setToken(response.data.response.accessToken)
              useJwt.setRefreshToken(response.data.response.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
              this.$router.push({ name: 'home' })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            } else {
              // This block handles unsuccessful login attempts
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }
          }).catch(error => {
            console.log('error', error)

            // Handle any unexpected errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response?.data?.message || 'Login failed. Please try again.',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        } 
      })
    }
,
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
